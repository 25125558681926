/*----------------------
## Table Contents ##
* 01. FONTS
* 02. COLORS
* 03. ICONS
* 03. LAYOUTS
* 04. COMPONENTS
* 04. PREVIEWS
----------------------*/

/*Fonts*/

/*Colors*/
:root {
  --blue-1: #007aff;
  --blue-2: #0a84ff;
  --blue-3: #409cff;
  --blue-4: #0040dd;
  --green-1: #34c759;
  --green-2: #30d158;
  --green-3: #30db5b;
  --green-4: #248a3d;
  --indigo-1: #5856d6;
  --indigo-2: #5e5ce6;
  --indigo-3: #7d7aff;
  --indigo-4: #3634a3;
  --orange-1: #ff9500;
  --orange-2: #ff9f0a;
  --orange-3: #ffb340;
  --orange-4: #c93400;
  --pink-1: #ff2d55;
  --pink-2: #ff375f;
  --pink-3: #ff6482;
  --pink-4: #d30f45;
  --purple-1: #af52de;
  --purple-2: #bf5af2;
  --purple-3: #da8fff;
  --purple-4: #8944ab;
  --red-1: #ff3b30;
  --red-2: #ff453a;
  --red-3: #ff6961;
  --red-4: #d70015;
  --teal-1: #5ac8fa;
  --teal-2: #64d2ff;
  --teal-3: #70d7ff;
  --teal-4: #0085a4;
  --yellow-1: #ffcc00;
  --yellow-2: #ffd60a;
  --yellow-3: #ffd426;
  --yellow-4: #b2d60a;
}
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif !important;
  font-size: 1.05rem;
}
/*ICONS*/

/*LAYOUT*/
body {
  background-color: #f0f2f5 !important;
}
input {
  background-color: #ffffff;
}
a:hover {
  text-decoration: none;
}

.at-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sv-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-cards {
  width: 700px;
}

.fc-block-container {
  margin: 15px 0px;
  display: flex;
  flex-direction: column;
}
.fc-block {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 10px 0px;
}
.fc-block-preview {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 12px 0px 35px;
  padding: 12px 0px;
}
.hover-item-container {
  margin: 50px 0px 0px;
  display: flex;
  flex-direction: row;
}
.hover-add-form-btn {
  position: relative;
  bottom: 22px;
  cursor: pointer;
}
.dotted-hover {
  height: 5px;
  width: 100%;
  border-bottom: 3px dotted #c4c4c4;
}

@media screen and (max-width: 720px) {
  .form-cards {
    width: 92vw;
    margin: 5px;
  }
}

.fc-block:focus {
  outline: none;
}
.form-title-group {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 40px;
  font-style: normal;
}
.form-subtitle {
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #363638;
  width: 100%;
}

@media (max-width: 1280px) {
  .form-subtitle {
    text-align: center;
  }
}
.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-silent {
  color: #8e8e93;
}

/*COMPONENTS*/
.brand-container {
  display: flex;
  flex-direction: row;
  margin: 5px;
  padding-top: 7px;
  cursor: pointer;
}
.brand-icon {
  height: auto;
  width: 160px;
}
.survey-text {
  color: #545456;
  margin-top: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  font-weight: bold;
}
.button:hover {
  text-decoration: none;
}
.button-md {
  width: 100px;
  height: 46px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
.button-block {
  width: 100%;
  height: 42px;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  margin-top: 35px;
}
.button-primary {
  background: var(--teal-4);
  color: white;
  border: 1px solid var(--teal-4);
  box-sizing: border-box;
  border-radius: 3px;
}

.button-outline-primary {
  background: #ffffff;
  border: 1px solid var(--teal-4);
  box-sizing: border-box;
  border-radius: 3px;
  color: var(--teal-4);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.input-control {
  height: 42px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #363638;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 77px 11px 14px;
}

.input-control-block {
  width: 100%;
  height: 42px;
  background: #ffffff;
  border: 1px solid #363638;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 77px 11px 14px;
}
.links {
  text-decoration: none;
  color: black;
}
.links:hover {
  text-decoration: none;
}

.fc-block-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.fc-preview-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #444446;
  padding: 20px 35px 0px;
}

.sr-number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 5px;
  width: 65px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.sr-number > span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #363638;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0 7px;
}

.sr-number > img {
  width: 20px;
  height: 20px;
}

.form-input-alt {
  background: #f2f2f7;
  border-radius: 5px;
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #363638;
  border: none;
}

.fc-question-input {
  width: 100%;
  height: 45px;
  padding-left: 15px;
  margin: 15px 10px;
  font-size: 18px;
}

.fc-dropdown {
  margin: 16px 16px 0px;
  background: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 35px;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  padding: 0 50px 0px 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("../images/icons/fc-drop-arrow.png") right no-repeat;
  background-position-x: calc(100% - 15px);
  border: none;
}
.fc-dropdown:focus {
  outline: none;
}
.fc-dropdown-toggle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}
.fc-block-body {
  width: 100%;
  padding: 0px 10%;
}

@media only screen and (max-width: 600px) {
  .fc-block-body {
    padding-right: 80px;
  }
}

.fc-block-footer {
  width: 100%;
  border-top: 1px solid #c4c4c4;
}
.fc-block-footer-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 15px;
}
.footer-switch {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
}
.footer-icon-container {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  margin-top: 15px;
  background-color: #ffffff;
}
.footer-icon-container:hover {
  background-color: rgba(0, 133, 164, 0.15);
}
.footer-switch > p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #363638;
  padding: 3px 17px;
}
.qstype-container {
  margin: 0px 0px 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.qs {
  display: flex;
  flex-direction: column;
  margin: 5px 0px 0px;
  width: 100%;
}
.qs-label {
  border: none;
  /*border-bottom: 1px solid #8e8e93;*/
  width: 100%;
  margin: 0 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #8e8e93;
  padding-bottom: 8px;
}
.qs-label:focus {
  outline: none;
}
.qs-label::-webkit-input-placeholder,
.qs-label:-ms-input-placeholder,
.qs-label::placeholder {
  /* Edge, Firefox*/
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #8e8e93;
  padding-bottom: 2px;
}
.cross-btn {
  cursor: pointer;
}
.qs-activity {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin: 16px 0px;
  position: relative;
  right: 19px;
}
.qs-activity > h6 {
  margin: 5px 15px;
}
.qs-activity > h6,
.qs-activity-alt > label {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #0085a4;
  cursor: pointer;
}
.qs-activity-alt > input {
  margin-right: 5px;
  cursor: pointer;
}
.form-group-alt {
  display: flex;
  flex-direction: row;
}

.form-group-alt > input[type="number"] {
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 5px;
  width: 80px;
  height: 35px;
  padding: 8px 16px;
}
.form-group-alt > input[type="checkbox"] {
  height: 15px;
  width: 15px;
  border: 1px solid black;
  vertical-align: middle;
  position: relative;
  bottom: 0.08em;
}
.form-group-alt > input[type="date"],
.form-group-alt > label > input[type="date"],
.form-group-alt > input[type="time"],
.form-group-alt > label > input[type="time"] {
  border: 1px solid #f2f2f7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  height: 46px;
  margin: 0 15px;
}

.form-card-label {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #0085a4;
  margin: 10px;
  white-space: nowrap;
}
.qs-text-input {
  height: 36px;
  margin-bottom: 10px;
}
.range-label {
  margin-top: 25px;
}

/*PREVIEWS*/
.header {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1px;
  padding-left: 1%;
  padding-right: 1%;
  z-index: 999;
}
.hnav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hnav-container:hover {
  text-decoration: none;
}
.hnav > p {
  color: var(--teal-4);
  font-size: 16px;
  font-weight: bold;
  margin: 15px;
}
.hnav > img {
  cursor: pointer;
}
.form-footer {
  margin: 25px 38px 0;
  text-align: center;
}
.footer-link {
  color: #007ae1;
  text-decoration: underline;
}
.form-container {
  background: #ffffff;
  width: 100%;
  min-height: 100vh;
  padding: 80px 10%;
}
.form-container-lg {
  background: #ffffff;
  border: 1px solid #8e8e93;
  border-radius: 15px;
  width: 445px;
  padding-bottom: 50px;
  margin: 50px 0px;
}
.form-title-alt {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin: 25px 35px 10px;
}
.form-title-text {
  font-weight: bolder;
  font-size: 24px;
  line-height: 25px;
  text-align: left;
  width: 100%;
}
@media (max-width: 1280px) {
  .form-title-text {
    text-align: center;
  }
}
.highlight-auth {
  color: #007ae1;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}
.form-inner {
  width: 100%;
  margin: 25px 5px;
}
.fp-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  color: #007ae1;
  cursor: pointer;
  text-decoration: none !important;
}
.fp-text:hover {
  text-decoration: none !important;
  cursor: pointer;
}

.social-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.socials-logo {
  height: 50px;
  width: 50px;
  margin: 0 20px;
  cursor: pointer;
}
@media screen and (max-width: 720px) {
  .socials-logo {
    height: 5vw;
    width: 5vw;
    margin: 0 1vw;
    cursor: pointer;
  }
}
.form-footer {
  margin: 25px 38px 0;
  text-align: center;
}
.footer-link {
  color: #007ae1;
  text-decoration: underline;
}
.invalid {
  font-size: 12px;
  font-style: italic;
  color: var(--red-4);
  display: inline-block;
  margin: 3px;
}
.sv-grid {
  display: grid;
  grid-template-columns: auto auto;
}
.sv-block-header {
  margin: 15px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  width: 700px;
}

@media screen and (max-width: 720px) {
  .sv-block-header {
    margin: 15px 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    width: 92vw;
  }
}

.sv-block-image-container {
  width: 100%;
  height: 149px;
}
.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.preview-image-actions {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 700px;
  padding: 0 8px;
}
.preview-cancel {
  position: relative;
  bottom: 140px;
  background-color: #d1d1ea;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  cursor: pointer;
}
.preview-cancel:hover {
  background-color: #ffffff;
}
.preview-reupload {
  position: relative;
  bottom: 60px;
  background-color: #d1d1ea;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
}
.preview-reupload:hover {
  background-color: #ffffff;
}
.sv-block-image-selector {
  background: #0085a4;
  padding: 10px;
}
.image-selector {
  height: 50px;
  width: 50px;
  background: #d1d1ea;
  color: #1c1c1e;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-selector-text {
  color: #d1d1ea;
  text-align: left;
  margin-top: 15px;
  margin-left: 10px;
}
.header-form-group {
  display: flex;
  flex-direction: column;
  padding: 0 15px 0;
  background: #ffffff;
  width: 100%;
}
.survey-title {
  margin: 5px 0px 0px;
  padding: 8px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  color: #444446;
}
.survey-title::-webkit-input-placeholder,
.survey-title:-ms-input-placeholder,
.survey-title::placeholder {
  /* Edge, Firefox*/
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  color: #444446;
}
.survey-title:focus {
  outline: none;
  border-bottom: 2px solid var(--teal-4);
}
.survey-desc {
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #444446;
  width: 100%;
  border: none;
  overflow: hidden;
}
.survey-desc:focus {
  outline: none;
}
.survey-desc::-webkit-input-placeholder,
.survey-desc:-ms-input-placeholder,
.survey-desc::placeholder {
  /* Edge, Firefox*/
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #444446;
}
.header-form {
  border-bottom: 1px solid #aeaeb2;
  border-top: 1px solid #aeaeb2;
  padding-top: 15px;
  margin-bottom: 15px;
}
.sv-block-aside {
  position: relative;
  bottom: 360px;
  left: 450px;
  margin-top: 15px;
  width: 160px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0 0 10px 3px rgba(142, 142, 147, 0.7);
  border-radius: 5px;
}
.sv-question-list {
  width: 160px;
  text-align: left;
  padding-top: 8px;
}
.sv-question-list > li {
  list-style-type: none;
}
.sv-question {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 5px;
  color: #363638;
  margin: 8px 5px;
  cursor: pointer;
}
.sv-question:hover {
  background: #f2f2f2;
}
.sv-question-icon {
  margin: 0 10px 10px 8px;
}
.sv-preview-header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
}
.sv-preview-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #444446;
}
.sv-preview-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #444446;
}
.add-form-btn {
  float: right;
  position: relative;
  left: 350px;
  bottom: 40px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
.add-form-btn-tr {
  float: right;
  position: relative;
  left: 350px;
  bottom: 40px;
  cursor: pointer;
  -ms-transform: rotate(45deg); /* IE 9 */
  transform: rotate(45deg);
  height: 30px;
  width: 30px;
}
@media screen and (max-width: 800px) {
  .add-form-btn,
  .add-form-btn-tr {
    display: none;
  }
}

.returnLink {
  margin: 25px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(142, 142, 147, 0.5);
  color: #007ae1;
}

.toastLink {
  color: white;
  font-weight: bolder;
  text-decoration: underline;
}
.toastLink:hover {
  color: white;
  font-weight: bolder;
  text-decoration: underline;
}
.fb-button {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #ffffff;
  border-color: grey;
}
.dropdown-type-icon {
  width: 20px;
  height: 20px;
}
.required-checkbox {
  margin-top: 6px;
  font-size: 10px;
}
.required-astirk {
  color: var(--red-4);
}
@media screen and (max-width: 720px) {
  .required-checkbox {
    margin-top: 6px;
    font-size: 1vw;
  }
}
.workplace-container {
  display: flex;
  flex-direction: column;
  margin-left: 250px;
}
.workplace-block {
  margin: 20px 50px;
}
.workplace-title > .title {
  font-size: 20px;
  line-height: 29px;
  text-align: left;
  color: #1c1c1e;
  margin-bottom: 20px;
}
.workplace-cards {
  display: flex;
  flex-direction: row;
}

.workplace-card {
  width: 174px;
  height: 225px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-left: 0px;
  margin-right: 25px;
  cursor: pointer;
}
.workplace-card > img {
  height: 130px;
  width: 174px;
}
.workplace-text {
  display: flex;
  flex-direction: column;
  padding: 8px;
}
.user-survey-text {
  font-size: 14px;
  line-height: 29px;
  text-align: left;
  color: #1c1c1e;
}
.user-survey-response {
  font-size: 12px;
  text-align: left;
  color: #1c1c1e;
  background-color: #f2f2f7;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.template-header {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1e;
}
.template-type {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 45%;
  line-height: 18px;
  border: 1px solid #1c1c1e;
  border-radius: 5px;
  padding: 5px;
}
.template-header-create-survey {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 27px;
  color: #1c1c1e;
  width: 80%;
}
.create-survey-icon {
  width: 40px;
  height: 40px;
}
.all-template {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}
.all-template-arrow-container {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-color: #ffffff;
  margin-bottom: 10px;
  cursor: pointer;
}

.all-template-text {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
}

/*New CSS*/
.form-field {
  max-width: 100%;
  margin: 15px 0px;
}
.btn-text {
  font-size: 12px;
}
.social-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.social-login-subtile-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.social-login-subtile-container > div {
  height: 1px;
  width: 30%;
  background-color: #c4c4c4;
  margin: 0px 15px 14px;
}
.footer-item {
  font-size: 13px;
  margin: 5px;
  color: #636366;
}
@media screen and (max-width: 550px) {
  .social-login-subtile-container > div {
    height: 1px;
    width: 20%;
    background-color: #c4c4c4;
    margin: 0px 15px 14px;
  }
  .social-login-subtile-container > p {
    font-size: 14px;
  }
}
@media screen and (max-width: 720px) {
  .footer-item {
    font-size: 2vw;
    margin: 5px;
    color: #636366;
  }
}

.form-footer-grid {
  margin: 10px 0px;
}

.login-cover-container {
  background: linear-gradient(128.46deg, #0085a4 1.86%, rgba(255, 45, 85, 0.68) 122.68%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-details {
  background: rgba(0, 0, 0, 0.7);
  height: 80%;
  width: 80%;
}

.register-tab-1 {
  background: url("../images/preview/business-man-financial-inspector-secretary-making-report-concept.jpg") no-repeat
    center center;
  background-size: cover;
  padding: 50px 40px;
}
.register-tab-2 {
  background: url("../images/preview/business-people-working-laptop-meeting.jpg") no-repeat center center;
  background-size: cover;
  padding: 50px 40px;
}
.register-tab-3 {
  background: url("../images/preview/startup-business-teamwork-meeting-concept.jpg") no-repeat center center;
  background-size: cover;
  padding: 50px 40px;
}
.register-tab-4 {
  background: url("../images/preview/workplace-results-professional-report-accounting-during.jpg") no-repeat center
    center;
  background-size: cover;
  padding: 50px 40px;
}

.register-tab-1,
.register-tab-2,
.register-tab-3,
.register-tab-4 {
  height: 100%;
}

@media (max-width: 1350px) {
  .register-tab-1,
  .register-tab-2,
  .register-tab-3,
  .register-tab-4 {
    height: 876px;
  }
}
@media (max-width: 2150px) {
  .register-tab-1,
  .register-tab-2,
  .register-tab-3,
  .register-tab-4 {
    min-height: 800px;
  }
}
.registration-tab-header {
  margin-right: 70px;
  padding-right: 70px;
}
.registration-tab-header > h5 {
  color: #ffffff;
  font-size: 28px;
}

.tab-header-container {
  margin-top: 60px;
}
.tab-header {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  padding-bottom: 10px;
  color: white;
  font-size: 18px;
  border-bottom: 4px solid #ffffff;
  width: 25%;
  cursor: pointer;
}
.tab-header > span {
  padding: 6px 5px 0px 1px;
}
.tab-logo-container {
  margin-right: 5px;
  margin-top: 8px;
}
.active {
  padding-right: 10px;
  padding-bottom: 10px;
  color: #ff2d55;
  font-size: 18px;
  border-bottom: 4px solid #ff2d55;
  width: 24%;
  cursor: pointer;
}
.tab-header + .active {
  padding-right: 10px;
  padding-bottom: 10px;
  color: #ff2d55;
  font-size: 18px;
  border-bottom: 4px solid #ff2d55;
  width: 24%;
  cursor: pointer;
}
.activity-list-container {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  position: relative;
  right: 35px;
}
.activity-logo-container {
  margin-right: 10px;
}
.login-detail-text {
  color: #ffffff;
  margin: 40px 25px 0px 40px;
}
.login-bar-chart {
  margin: 40px 30px;
}
.login-detail-text > p {
  margin-top: 20px;
}
.activity-item {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  color: #ffffff;
  font-size: 20px;
  width: 100%;
}
.login-activity-list {
  margin: 25px 30px;
}
.password-strength-progress {
  display: flex;
  flex-direction: row;
}
.password-progress {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 300px;
  margin: 10px 0px 0px 25px;
}
.progress-container {
  width: 60px;
}
@media (max-width: 600px) {
  .password-progress {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 300px;
    font-size: 14px;
    margin: 15px 5px;
  }
  .progress-container {
    width: 30px;
  }
}

.return-link {
  margin: 25px;
}
.reset-container {
  padding: 0px 30%;
}
@media screen and (max-width: 900px) {
  .reset-container {
    padding: 0px 10%;
  }
}

.aside-container {
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 14px 14px 0px;
  width: 100%;
  height: 100vh;
  min-height: 100% !important;
}
.workplace-block-sm {
  width: 100%;
  padding: 25px;
}
.workplace-sm-title-group {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  border-bottom: 1px solid #c4c4c4;
}

.aside-header {
  font-size: 20px;
}
.aside-block {
  border-top: 1px solid #ced0d4;
}
.aside-header-block {
  color: #888888;
  font-size: 16px;
  margin-top: 12px;
  text-decoration: none;
}
.aside-grid {
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.aside-block-items {
  display: flex;
  flex-direction: row;
  color: #333333;
  border-radius: 5px;
  padding-left: 10px;
  cursor: pointer;
  margin: 5px 0px;
  transition: 0.3s ease;
}
.page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.page-cancel {
  margin: 2px 5px 0px;
  z-index: 999;
}
.aside-block-items + .page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #333333;
  border-radius: 5px;
  padding-left: 10px;
  cursor: pointer;
  margin: 5px 0px;
}
@media screen and (max-width: 720px) {
  .aside-block-items {
    display: flex;
    flex-direction: row;
    color: #333333;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
    margin: 5px 0px;
  }
}
.workplace-aside-block-items-sm {
  display: flex;
  flex-direction: row;
  color: #333333;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  padding-left: 5px;
  transition: 0.3s ease;
  cursor: pointer;
}
.aside-block-items > p,
.workplace-aside-block-items-sm > p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin: 10px 5px;
  cursor: pointer;
}

.on {
  background: #c3d7dc;
  border: 1px solid #0085a4;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 133, 164, 0.25);
  border-radius: 50px;
}
.aside-block-items:hover,
.workplace-aside-block-items-sm:hover {
  background-color: rgba(0, 133, 164, 0.15);
  border-radius: 20px;
  color: rgba(0, 133, 164, 1);
}

.at {
  background-color: rgba(0, 133, 164, 0.15);
  border-radius: 20px;
  color: rgba(0, 133, 164, 1);
  display: flex;
  flex-direction: row;
}
.aside-block-items + .at {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 133, 164, 0.15);
  border-radius: 20px;
  color: rgba(0, 133, 164, 1);
}
.workplace-aside-block-items-sm + .on {
  background: #c3d7dc;
  border: 1px solid #0085a4;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 133, 164, 0.25);
  border-radius: 50px;
}
.aside-block-item-icon {
  margin: 4px 10px;
}
@media screen and (max-width: 720px) {
  .aside-block-items > p,
  .workplace-aside-block-items-sm > p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 2vw;
    margin: 10px 5px;
    cursor: pointer;
  }
  .aside-block-item-icon > img {
    height: 3vw;
    width: 3vw;
  }
  .aside-block-item-icon {
    margin: 4px 5px;
  }
}
@media screen and (max-width: 550px) {
  .aside-block-items > p,
  .workplace-aside-block-items-sm > p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 2vw;
    margin: 10px 0px;
    cursor: pointer;
  }
  .aside-block-item-icon {
    margin: 0px 0px;
  }
  .aside-block-item-icon > img {
    height: 3vw;
    width: 3vw;
    margin-right: 6px;
  }
}
.aside-header-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.aside-header-group > p {
  color: #0085a4;
  font-weight: 600;
  font-size: 14px;
  margin-top: 12px;
  cursor: pointer;
}

.create-survey-container {
  margin: 30px 0px 0px;
  padding-bottom: 15px;
  border-bottom: 1px solid #c4c4c4;
  cursor: pointer;
}
.create-survey-input {
  margin: 20px 0px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
}
.create-survey-icon-sm {
  height: 18px;
  width: 18px;
}
.workspace-file-container {
  margin-top: 30px;
}
.workspace-category-container {
  margin-top: 20px;
}
.workspace-category-pill {
  padding: 0px 0px 5px 10px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}
.category-pill-border {
  height: 1px;
  background: #c4c4c4;
  margin-top: 15px;
}

.category-pill-icon-sm {
  height: 15px;
  width: 15px;
}

.category-pill-icon {
  height: 18px;
  width: 18px;
}
.category-pill-text-sm {
  font-weight: 600;
  font-size: 10px;
  margin-left: 5px;
}
.category-pill-text {
  font-weight: 600;
  font-size: 12px;
  margin-left: 5px;
}

.survey-aside {
  padding: 15;
}
.survey-aside-sm {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
  height: 55px;
  width: 100%;
  padding: 5px 14px;
}

.fc-menu-item {
  color: #0085a4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-item-icon {
  font-size: 5px;
  margin-right: 15px;
}
.menu-item-text {
  margin-right: 10px;
}

.fc-select {
  border: none;
}
@media screen and (max-width: 720px) {
  .fc-select {
    border: none;
    margin: 20px 0px 0px 10px;
  }
  .category-pill-icon {
    height: 3vw;
    width: 3vw;
  }
  .category-pill-text {
    font-weight: 600;
    font-size: 1.8vw;
    margin-left: 5px;
  }
}

.survey-nav-tabs {
  position: relative;
  bottom: 36px;
  margin: 0 auto;
}
.survey-nav-tabs-sm {
  margin-top: 5px;
  position: relative;
  bottom: 5px;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}
.survey-tabs-active {
  border-bottom: 3px solid #0085a4;
  color: #0085a4;
}
.survey-tabs-item {
  margin-right: 25px;
  padding-bottom: 2px;
  cursor: pointer;
}
.survey-tabs-item + .survey-tabs-active {
  border-bottom: 3px solid #0085a4;
  color: #0085a4;
}
.qs-preview-label {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #111111;
}
.fc-flex-preview {
  background-color: rgba(0, 133, 164, 0.15);
  border-bottom: none;
}
.pagination-text {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #888888;
}
.MuiTableCell-root {
  border-bottom: none !important;
}

.publish-response {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 25px;
  border-top: 7px solid #0085a4;
  padding: 12px 0px;
  width: 700px;
}
.publish-response-text > h3 {
  font-size: 30px;
  line-height: 34px;
  color: #0085a4;
}
.publish-response-text > p {
  color: rgba(136, 136, 136, 1);
  font-weight: normal;
  font-size: 16px;
}
.publish-link-box {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 25px 0px;
  padding: 12px 0px;
  width: 700px;
  padding: 20px;
}
.copy-text-btn {
  background: #e9ecef;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  margin: 20px 0px;
  cursor: pointer;
}
.copied-text {
  background: #0085a4;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  margin: 20px 0px;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .publish-response {
    width: 70%;
    margin: 0px;
    width: 0px;
  }
}
.rev-link {
  background: #ffffff;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  position: relative;
  right: 5px;
  margin: 20px 0px;
}
.completion-state {
  position: fixed;
  bottom: 30px;
  right: 22%;
}
.completion-state-sm {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  height: 65px;
  padding-top: 5px;
  padding-left: 15px;
}
.completion-link {
  background-color: #ffffff;
  border-radius: 100px;
  padding: 10px;
  margin-bottom: 20px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.competion-round {
  border: 2px solid #0085a4;
  background-color: transparent;
  border-radius: 100px;
  padding: 10px;
  height: 50px;
  width: 50px;
  margin-top: 20px;
  cursor: pointer;
}
.completion-round-text {
  font-size: 13px;
}

@media screen and (max-width: 800px) {
  .publish-response,
  .publish-link-box,
  .publish-link {
    width: 90%;
  }
}
.responseContainer {
  width: 100%;
}
.response-card-container {
  width: 700px;
  margin: 25px 0px 80px;
  background: white;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.pagination-container {
  width: 700px;
}
.responsive-icon {
  transform: scale(0.9);
  margin-bottom: 2px;
}
@media screen and (max-width: 720px) {
  .pagination-container {
    width: 90%;
    margin: 25px 0px 80px;
  }
  .response-card-container {
    width: 95%;
    margin: 25px 10px 80px;
  }
  .responsive-icon {
    transform: scale(0.8);
  }
}
@media screen and (max-width: 400px) {
  .responsive-icon {
    transform: scale(0.5);
  }
}
.chart-tab-icons {
  box-shadow: "0px 0px 5px rgba(142, 142, 147, 0.25)";
  border-radius: "2px";
  padding: 2px;
  cursor: "pointer";
}

.slick-prev:before,
.slick-next:before {
  color: #0085a4 !important;
}
.slick-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333333;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 5px 1px;
  margin: 0px 25px;
  border: 1px solid #0085a4;
  cursor: pointer;
}
.slick-clicked {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 5px 1px;
  margin: 0px 25px;
  border: 1px solid #0085a4;
  cursor: pointer;
  background: rgba(0, 133, 164, 0.15) !important;
  color: #0085a4 !important;
}

.slick-item-text {
  padding-left: 5px;
  font-size: 14px;
}
.tableCell {
  border: 1px solid rgba(142, 142, 147, 0.4);
}
.tableCell + .head {
  border: 1px solid rgba(142, 142, 147, 0.4);
  font-weight: bold;
}
.response-success-svg {
  height: 10vw;
  width: 10vw;
}
@media screen and (max-width: 1200px) {
  .slick-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #333333;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 5px 1px;
    margin: 0px 5px;
    border: 1px solid #0085a4;
    cursor: pointer;
  }
  .slick-item-text {
    padding-left: 5px;
    font-size: 1vw;
  }
}

.box-plot {
  transform: scale(0.8);
  position: relative;
  left: -150px;
}
.boxPlot-container {
  position: relative;
  height: 120px;
}
@media screen and (max-width: 720px) {
  .boxPlot-container {
    width: 100%;
    height: 100px;
    position: relative;
  }
  .box-plot {
    transform: scale(0.5);
    position: absolute;
    left: -200px;
  }
}
@media screen and (max-width: 350px) {
  .boxPlot-container {
    width: 100%;
    height: 100px;
    position: relative;
  }
  .box-plot {
    transform: scale(0.3);
    position: absolute;
    left: -250px;
  }
}

.mini-survey-aside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}

.slider-selected {
  border-radius: 2px;
  display: flex;
  width: 5px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  background-color: #0085a4;
}
.slider-unselected {
  border-radius: px;
  padding: 2px;
  margin-bottom: 5px;
  background-color: white;
}
.slider-text-active {
  color: white;
  font-weight: 500;
  margin-left: 18px;
}
.slider-text-inactive {
  color: #0085a4;
  font-weight: 500;
  margin-left: 18px;
}

.price-plan-container {
  background: #ffffff;
  margin: 10px 15px;
  padding: 15px 30px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 520px;
  position: relative;
}
@media (max-width: 400px) {
  .price-plan-container {
    min-height: 610px;
  }
}
.price-plan-btn {
  display: block;
  padding: 8px;
  border: 1px solid var(--teal-4);
  background: white;
  color: #0085a4;
  font-weight: bold;
  text-align: center;
  width: 90%;
  position: absolute;
  margin: 20px auto;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.3s ease;
}
.price-plan-btn:hover {
  background: var(--teal-4);
  color: white;
}

.price-pill {
  background-color: #ffffff;
  border: 1px solid var(--teal-4);
  border-radius: 20px;
  width: 150px;
  height: 40px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.price-pill > span {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.833333px;
}
.over {
  position: relative;
  left: 30px;
}
.activeTab {
  z-index: 1000;
  background-color: var(--teal-4);
  color: #ffffff;
}
.MuiSelect:focus {
  background: transparent !important;
}

/* Stripe Styling */
.StripeElement {
  border: 1px solid rgb(133, 133, 133);
  padding: 10px;
  border-radius: 5px;
}

.StripeElement--focus {
  border: 2px solid #0085a4;
}

.StripeElement--invalid {
  border: 1px solid red;
}
